<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div
    id="page_body"
    class="page_body"
    :style="loading ? '' : 'overflow-y: auto;'"
  >
    <progressBox v-if="loading" ref="progressBox"></progressBox>
    <div class="page_box">
      <watermark text="天马洞察"></watermark>
      <img class="top_bg_class" src="@/assets/top_bg.png" alt="" />
      <div class="info">
        <div class="info_title">
          <div class="info_title_text">天马洞察</div>
          <div class="num">生成时间:{{ baseInfo.genDate }}</div>
        </div>
        <div class="user_box">
          <div class="user_class max_user">
            <div class="name_img1" v-if="baseInfo.name">
              {{ baseInfo.name.substring(0, 1) }}
            </div>
            <div class="info_text_box">
              <div class="flex-align wrap">
                <div class="user_name">{{ baseInfo.name }}</div>
                <div class="flex-align">
                  <div class="user_describe">
                    <span v-if="baseInfo.hasDelayPay == 1">分期</span>
                    <span v-if="baseInfo.hasLease == 1">租机</span>
                    <span v-if="baseInfo.hasLoan == 1">网贷</span>
                    <span v-if="baseInfo.hasStages == 1">回收</span>
                  </div>
                </div>
                <div class="city" v-if="baseInfo.nativePlaceStats">
                  {{ baseInfo.nativePlaceStats }}
                </div>
              </div>
              <div class="flex-align wrap info_center_text">
                <div class="box_text" style="margin-left: 0">
                  {{ baseInfo.age }}
                </div>
                <div class="interval">|</div>
                <div class="box_text">{{ baseInfo.sex }}</div>
                <div class="interval">|</div>
                <div class="box_text">户籍：{{ baseInfo.nativePlace }}</div>
                <div class="interval">|</div>
                <div class="box_text">身份证号码：{{ baseInfo.cardId }}</div>
              </div>
              <div class="img_box">
                <div class="flex-align img_item" v-if="baseInfo.wxDateGap > 0">
                  <img class="img_url" src="@/assets/wx.png" mode="widthFix" />

                  <span
                    >{{ baseInfo.wxStartTime }}~{{ baseInfo.wxEndTime }}</span
                  >
                  <span class="time">({{ baseInfo.wxDateGap }}个月)</span>
                </div>
                <div class="flex-align img_item" v-if="baseInfo.aliDateGap > 0">
                  <img class="img_url" src="@/assets/zfb.png" mode="widthFix" />

                  <span
                    >{{ baseInfo.aliStartTime }}~{{ baseInfo.aliEndTime }}</span
                  >
                  <span class="time">({{ baseInfo.aliDateGap }}个月)</span>
                </div>
              </div>
            </div>
          </div>
          <div class="user_class min_user">
            <div class="info_text_box">
              <div class="flex-align">
                <div class="name_img2" v-if="baseInfo.name">
                  {{ baseInfo.name.substring(0, 1) }}
                </div>

                <div>
                  <div class="flex-align">
                    <div class="user_name">{{ baseInfo.name }}</div>
                    <div class="city" v-if="baseInfo.nativePlaceStats">
                      {{ baseInfo.nativePlaceStats }}
                    </div>
                  </div>

                  <div class="flex-align" style="margin-top: 10px">
                    <div class="user_describe">
                      <span v-if="baseInfo.hasDelayPay == 1">先用后付</span>
                      <span v-if="baseInfo.hasLease == 1">租机</span>
                      <span v-if="baseInfo.hasLoan == 1">网贷</span>
                      <span v-if="baseInfo.hasStages == 1">分期</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="info_center_text">
                <div class="flex-align" style="margin-bottom: 5px">
                  <div class="box_text" style="margin-left: 0">
                    {{ baseInfo.age }}
                  </div>
                  <div class="interval">|</div>
                  <div class="box_text">{{ baseInfo.sex }}</div>
                  <div class="interval">|</div>
                  <div class="box_text">身份证号码：{{ baseInfo.cardId }}</div>
                </div>

                <div class="box_text">户籍：{{ baseInfo.nativePlace }}</div>
              </div>
              <div class="img_box">
                <div class="flex-align img_item" v-if="baseInfo.wxDateGap > 0">
                  <img class="img_url" src="@/assets/wx.png" mode="widthFix" />

                  <span
                    >{{ baseInfo.wxStartTime }}~{{ baseInfo.wxEndTime }}</span
                  >
                  <span class="time">({{ baseInfo.wxDateGap }}个月)</span>
                </div>
                <div class="flex-align img_item" v-if="baseInfo.aliDateGap > 0">
                  <img class="img_url" src="@/assets/zfb.png" mode="widthFix" />

                  <span
                    >{{ baseInfo.aliStartTime }}~{{ baseInfo.aliEndTime }}</span
                  >
                  <span class="time">({{ baseInfo.aliDateGap }}个月)</span>
                </div>
              </div>
            </div>
          </div>
          <div class="user_left">
            <div class="user_left_item">
              <div style="display: flex; align-items: center">
                <img class="user_left_img" src="@/assets/shouru.png" alt="" />
                <div class="user_left_num">{{ baseInfo.inTotalAmount }}</div>
              </div>

              <div class="user_left_text">总收入</div>
            </div>
            <div class="user_left_item" style="margin-right: 0">
              <div style="display: flex; align-items: center">
                <img class="user_left_img" src="@/assets/zhichu.png" alt="" />
                <div class="user_left_num">{{ baseInfo.outTotalAmount }}</div>
              </div>

              <div class="user_left_text">总支出</div>
            </div>
          </div>
        </div>
      </div>

      <billBox title="风险标注">
        <risklabelTable
          :list="risklabelList"
          :allTradeList="allTradeList"
        ></risklabelTable>
      </billBox>

      <!-- <billBox title="法院限高/执行信息">
      <courtInformation></courtInformation>
    </billBox> -->
      <el-row :gutter="10">
        <el-col :xs="24" :sm="12">
          <billBox title="活跃-日">
            <activeDay ref="activeDay"></activeDay>
          </billBox>
        </el-col>
        <el-col :xs="24" :sm="12">
          <billBox title="活跃-年">
            <activeYear ref="activeYear"></activeYear>
          </billBox>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :xs="24" :sm="12">
          <bankCardList :list="bankList" :bankRadarData="bankRadar"  ref="bankList"></bankCardList>
        </el-col>
        <el-col :xs="24" :sm="12">
          <counterpartyList
            :list="counterparty"
            :allTradeList="allTradeList"
          ></counterpartyList>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :xs="24" :sm="24">
          <billBox title="月均收入/支出条形趋势图">
            <template v-slot:header>
              <div class="title_text">【单位:元】- 可判断用户的消费稳定性和消费水平</div>
            </template>
            <disbursement ref="disbursementEcharts"></disbursement>
          </billBox>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col
          :xs="24"
          :sm="12"
          v-for="(item, index) in hitTrade"
          :key="index"
        >
          <billBox :title="getBillChineseName(item.title)">
            <template v-slot:header>
              <div class="table_text">(命中{{ item.tipNum }}个平台)</div>
            </template>
            <allTable :tabList="item.list"></allTable>
          </billBox>
        </el-col>
        <el-col :xs="24" :sm="12">
          <billBox title="租赁识别">
            <identification :list="forecastList"></identification>
          </billBox>
        </el-col>
        <el-col :xs="24" :sm="12">
          <billBox title="异常交易数据">
            <abnormal :list="abnormalList"></abnormal>
          </billBox>
        </el-col>
        <el-col :xs="24" :sm="12">
          <billBox title="大额资金流向">
            <sankeyData ref="sankeyData"></sankeyData>
          </billBox>
        </el-col>
        <el-col :xs="24" :sm="12">
          <billBox title="交易对方-热门">
            <word :list="wordCloudList" @chartsClick="wordChartsClick"></word>
          </billBox>
        </el-col>
        <el-col :xs="24" :sm="12">
          <billBox title="交易对方-标签">
            <counterpartyTag
              ref="counterpartyPortrait"
              @chartsClick="chartsClick"
            ></counterpartyTag>
          </billBox>
        </el-col>
        <el-col :xs="24" :sm="12">
          <billBox title="画像标签">
            <portraitTag
              ref="userPortraitList"
              @chartsClick="chartsClick"
            ></portraitTag>
          </billBox>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24">
          <billBox title="查找账单内容">
            <billContent ref="bill" :list="allTradeList"></billContent>
          </billBox>
        </el-col>
      </el-row>

      <div class="footer">
        <div class="footer_title">报告使用说明：</div>
        <div class="footer_content">
          1.本报告内容仅通过邮箱收到的账单原件进行分析而成，依据使用单位的委托由我司代理完成。我司仅负责数据分析，不对报告内容准确性、完整性等问题承担任何责任。
        </div>
        <div class="footer_content">
          2.本报告仅供使用者参考，我司不承担据此报告产生的任何法律责任。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const sm4 = require("sm-crypto").sm4;
import { SM4Util } from 'sm4util'
import { getDetail } from "@/api/index";
import word from "@/components/word/word.vue";
import billBox from "@/components/billBox/billBox.vue";
import risklabelTable from "@/components/risklabelTable/risklabelTable.vue";
// import courtInformation from "@/components/courtInformation/courtInformation.vue";
import activeDay from "@/components/activeDay/activeDay.vue";
import activeYear from "@/components/activeYear/activeYear.vue";
import disbursement from "@/components/disbursement/disbursement.vue";
import counterpartyTag from "@/components/counterpartyTag/counterpartyTag.vue";
import portraitTag from "@/components/portraitTag/portraitTag.vue";
import counterpartyList from "@/components/counterpartyList/counterpartyList.vue";
import bankCardList from "@/components/bankCardList/bankCardList.vue";
import billContent from "@/components/billContent/billContent.vue";
import identification from "@/components/identification/identification.vue";
import allTable from "@/components/allTable/allTable.vue";
import sankeyData from "@/components/sankeyData/sankeyData";
import watermark from "@/components/watermark/watermark.vue";
import abnormal from "@/components/abnormal/abnormal.vue";
import progressBox from "@/components/progressBox/progressBox.vue";
export default {
  components: {
    progressBox,
    abnormal,
    watermark,
    sankeyData,
    billBox,
    word,
    risklabelTable,
    // courtInformation,
    activeDay,
    activeYear,
    disbursement,
    counterpartyTag,
    portraitTag,
    counterpartyList,
    bankCardList,
    billContent,
    identification,
    allTable,
  },
  data() {
    return {
      loading: true,
      id: "",
      baseInfo: {},
      bankList: [],
      counterparty: [],
      hitTrade: [],
      risklabelList: [],
      depositNum: 0,
      creditNum: 0,
      wordCloudList: [],
      forecastList: [],
      abnormalList: [],
      bankRadar:[],
      list: [
        {
          title: "租赁",
          tip: "(命中一个平台)",
          tabList: [
            {
              id: "1",
              tabName: "趣易租",
              tabTag: "在租",
              imgIcon: "",
              tabList: [
                {
                  tradingTime: "2024-08-27 11:43:59",
                  tradingObject: "拼多多平台商户",
                  transactionDes:
                    "99新 华为mate 30Pro 双卡双待 国行正品 租物,租金AuthNo:2023042410002001900596309042",
                  consumption: 276.19,
                  expenditureType: "不计收支",
                  billType: "支付宝",
                },
              ],
            },
            {
              id: "2",
              tabName: "中融小贷",
              tabTag: "在租",
              imgIcon: "",
              tabList: [
                {
                  tradingTime: "2024-08-27 11:43:59",
                  tradingObject: "拼多多平台商户",
                  transactionDes:
                    "99新 华为mate 30Pro 双卡双待 国行正品 租物,租金AuthNo:2023042410002001900596309042",
                  consumption: 276.19,
                  expenditureType: "不计收支",
                  billType: "支付宝",
                },
              ],
            },
          ],
        },
        {
          title: "网贷",
          tip: "命中一个平台",
          tabList: [
            {
              id: "1",
              tabName: "趣易租",
              tabTag: "在租",
              imgIcon: "",
              tabList: [
                {
                  tradingTime: "2024-08-27 11:43:59",
                  tradingObject: "拼多多平台商户",
                  transactionDes:
                    "99新 华为mate 30Pro 双卡双待 国行正品 租物,租金AuthNo:2023042410002001900596309042",
                  consumption: 276.19,
                  expenditureType: "不计收支",
                  billType: "支付宝",
                },
              ],
            },
            {
              id: "2",
              tabName: "中融小贷",
              tabTag: "在租",
              imgIcon: "",
              tabList: [
                {
                  tradingTime: "2024-08-27 11:43:59",
                  tradingObject: "拼多多平台商户",
                  transactionDes:
                    "99新 华为mate 30Pro 双卡双待 国行正品 租物,租金AuthNo:2023042410002001900596309042",
                  consumption: 276.19,
                  expenditureType: "不计收支",
                  billType: "支付宝",
                },
              ],
            },
          ],
        },
        {
          title: "先用后付",
          tip: "命中一个平台",
          tabList: [
            {
              id: "1",
              tabName: "趣易租",
              tabTag: "在租",
              imgIcon: "",
              tabList: [
                {
                  tradingTime: "2024-08-27 11:43:59",
                  tradingObject: "拼多多平台商户",
                  transactionDes:
                    "99新 华为mate 30Pro 双卡双待 国行正品 租物,租金AuthNo:2023042410002001900596309042",
                  consumption: 276.19,
                  expenditureType: "不计收支",
                  billType: "支付宝",
                },
              ],
            },
            {
              id: "2",
              tabName: "中融小贷",
              tabTag: "在租",
              imgIcon: "",
              tabList: [
                {
                  tradingTime: "2024-08-27 11:43:59",
                  tradingObject: "拼多多平台商户",
                  transactionDes:
                    "99新 华为mate 30Pro 双卡双待 国行正品 租物,租金AuthNo:2023042410002001900596309042",
                  consumption: 276.19,
                  expenditureType: "不计收支",
                  billType: "支付宝",
                },
              ],
            },
          ],
        },
      ],
    };
  },
  created() {
    this.loading = true;
  },
  mounted() {
    const params = new URLSearchParams(window.location.search);
    this.id = params.get("id");
    this.accountId = params.get("accountId");
    // console.log(this.id, "this.id");
    this.$nextTick(() => {
      this.$refs.progressBox.start();
      this.getDetail(this.id);
    });
  },
  methods: {
    getDetail() {


      getDetail({
        id: this.id,
        accountId: this.accountId,
      })
        .then((res) => {
          const msg = "hello world!"; // 可以为 utf8 串或字节数组
          const key = "9876543210sunneffedcba9876543777";
          let data = "";
          let sm_util2 = new SM4Util()

          // let encryptData = sm4.encrypt(JSON.stringify(res.data), key, {mode: 'cbc', iv: 'fedcba77775432100123456789abcdef'}) // 加密，cbc 模式
          // console.log(encryptData);
          try {
           // console.log(res.data);
            data = JSON.parse(sm4.decrypt(res.data, key)); // 解密，默认输出 utf8 字符串，默认使用 pkcs#7 填充（传 pkcs#5 也会走 pkcs#7 填充）
           //  data = JSON.parse(sm4.decrypt(res.data, key, {mode: 'cbc', iv: 'fedcba77775432100123456789abcdef'})) // 解密，cbc 模式); // 解密，默认输出 utf8 字符串，默认使用 pkcs#7 填充（传 pkcs#5 也会走 pkcs#7 填充）

             //data = sm4.decrypt(res.data, key) // 解密，不使用 padding
            // data = sm4.decrypt(res.data, key, {padding: 'none', output: 'array'}) // 解密，不使用 padding，输出为字节数组
            // data = sm4.decrypt(res.data, key, {
            //   mode: "cbc",
            //   iv: "fedcba77775432100123456789abcdef",
            // }); // 解密，cbc 模式

          //  sm_util2.decryptCustom_ECB(res.data, key)
            // console.log(data);
          } catch (error) {
             //console.log(error)
          }


          this.baseInfo = data.baseInfo;
          // 日活
          this.$refs.activeDay.initData(data.dayActive);
          //年活
          this.$refs.activeYear.initData(data.yearActive);

          // this.$refs.bankList.initRadarChart();

          this.bankList = data.bankCardList;
          this.risklabelList = data.risklabelList;
          this.wordCloudList = data.wordCloudList;
          this.forecastList = data.forecastList;
          this.abnormalList = data.abnormalList;
          this.depositNum = 0;
          this.creditNum = 0;
          this.bankList.forEach((item) => {
            if (item.cardType == "DEPOSIT") {
              this.depositNum += 1;
            } else if (item.cardType == "CREDIT") {
              this.creditNum += 1;
            }
          });
          console.log(data.counterpartyList, "1");
          this.counterparty = data.counterpartyList;

          this.$refs.counterpartyPortrait.initData(
            data.counterpartyPortraitList
          );
          this.$refs.userPortraitList.initData(data.userPortraitList);
          this.hitTrade = data.hitTradeList;
          this.allTradeList = data.allTradeList;

          this.$refs.disbursementEcharts.initData(data.disbursement);
          this.$refs.sankeyData.initData(data.counterpartyList);
          this.bankRadar = data.bankRadar
          console.log(this.bankRadar)

          this.$nextTick(() => {
            this.$refs.progressBox.animateProgress(100).then(() => {
              setTimeout(() => {
                this.loading = false;
              }, 300);
            });
          });
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    getBillChineseName(titleEn) {
      let titleCn = "";
      if (titleEn === "loan") {
        titleCn = "贷款";
      }

      if (titleEn === "rent") {
        titleCn = "租赁";
      }

      if (titleEn === "installment") {
        titleCn = "分期";
      }
      if (titleEn === "recycle") {
        titleCn = "回收";
      }

      return titleCn;
    },
    wordChartsClick(e) {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.bill.initName(e);
          document
            .getElementById("page_body")
            .scrollTo(0, document.getElementById("page_body").scrollHeight);
        }, 300);
      });
    },
    chartsClick(e) {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.bill.initTag(e);
          document
            .getElementById("page_body")
            .scrollTo(0, document.getElementById("page_body").scrollHeight);
        }, 300);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.page_body {
  height: 100vh;
  width: 100vw;
  padding: 0;
  overflow: hidden;
}
@media (min-width: 768px) {
  .page_box {
    position: relative;
    padding: 0 90px;
    overflow: hidden;
    background: #edf5ff;
    .top_bg_class {
      position: absolute;
      width: 100%;
      height: auto;
      top: 0;
      left: 0;
      z-index: 0;
    }
  }
  .info {
    border-radius: 20px;
    background-image: url("@/assets/title_bg.png");
    transition: 0.5s;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 380px;
    overflow: hidden;
    padding: 19px 0 29px 82px;
    margin: 40px 0;
    .info_title {
      font-size: 60px;
      font-weight: bold;
      .info_title_text {
        display: inline-block;
        background: linear-gradient(90deg, #018fff 0%, #ad00ff 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .num {
      font-size: 16px;
      color: rgba(9, 39, 83, 0.6);
    }
    .user_box {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      color: #333;
      width: 80%;
      margin-top: 20px;
    }
    .interval {
      margin: 0 8px;
    }
    .user_class {
      display: flex;
      width: calc(68% - 80px);
      background: linear-gradient(
        180deg,
        #ffffff 0%,
        rgba(255, 255, 255, 0.6) 100%
      );
      border-radius: 12px 12px 12px 12px;
      padding: 50px 40px;
      margin-right: 20px;
      .name_img1 {
        display: flex;
        justify-content: center;
        flex-shrink: 0;
        align-items: center;
        width: 68px;
        height: 68px;
        background: #018fff;
        border-radius: 50%;
        font-size: 36px;
        margin-right: 14px;
        color: #fff;
      }
      .name_img2 {
        display: none;
        justify-content: center;
        flex-shrink: 0;
        align-items: center;
        width: 68px;
        height: 68px;
        background: #018fff;
        border-radius: 50%;
        font-size: 36px;
        margin-right: 14px;
        color: #fff;
      }
      .info_text_box {
        display: block !important;
      }
      .user_name {
        font-size: 24px;
        margin-right: 8px;
      }
      .user_describe {
        font-size: 14px;
        color: #018fff;
        span {
          margin: 10px 13px;
          background: #018fff10;
          border-radius: 50px 50px 50px 0px;
          padding: 2px 10px;
        }
      }
      .wrap {
        flex-wrap: wrap;
      }
      .info_center_text {
        flex-wrap: wrap;
        margin: 13px 0 13px 0;
        .box_text {
          font-size: 14px;
          color: #333;
        }
      }

      .interval {
        margin: 0 8px;
      }
      .city {
        display: flex;
        align-items: center;
        height: 24px;
        background: #018fff;
        font-size: 12px;
        color: #fff;
        justify-content: center;
        border-radius: 5px;
        padding: 5px 20px;
      }
      .img_box {
        display: flex;
        align-items: center;
        flex-flow: row wrap;
        .img_item {
          margin-right: 21px;
          min-width: 200px;
          color: #092753;
          font-size: 14px;
          .img_url {
            width: 24px;
            margin-right: 10px;
          }
          .time {
            margin-left: 5px;
            color: red;
            font-weight: 700;
          }
        }
      }
    }
    .max_user {
      display: flex;
    }
    .min_user {
      display: none;
    }
    .user_left {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      width: calc(32% - 64px);
      .user_left_item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        background: linear-gradient(
          180deg,
          #ffffff 0%,
          rgba(255, 255, 255, 0.6) 100%
        );
        padding: 20px 30px;
        border-radius: 15px;
        overflow: hidden;
        &:first-child {
          margin-bottom: 10px;
        }
        .user_left_num {
          position: relative;
          z-index: 1;
          color: #018fff;
          font-size: 40px;
          font-weight: bold;
          margin: 0 9px 0 15px;
        }
        .user_left_text {
          // margin-top: 10px;
          font-size: 16px;
          color: #333;
        }
        .user_left_img {
          height: 60px;
          width: auto;
        }
      }
    }
  }

  .title_text {
    font-weight: 400;
    font-size: 12px;
    color: #333;
  }
}

/* 当屏幕宽度小于或等于768像素时，应用以下样式 */
@media (max-width: 768px) {
  .page_box {
    position: relative;
    padding: 0 10px;
    overflow: hidden;
    background: #edf5ff;
    .top_bg_class {
      display: none;
      position: absolute;
      width: 100%;
      height: auto;
      top: 0;
      left: 0;
      z-index: 0;
    }
  }
  .info {
    position: relative;
    z-index: 10;
    // background-image: url("@/assets/title_bg.png");
    transition: 0.5s;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: 10px 0;
    .info_title {
      font-size: 30px;
      font-weight: bold;
      .info_title_text {
        display: inline-block;
        background: linear-gradient(90deg, #018fff 0%, #ad00ff 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .num {
      font-size: 16px;
      color: rgba(9, 39, 83, 0.6);
    }
    .user_box {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      font-size: 14px;
      color: #333;
      width: 100%;
      margin-top: 20px;
    }
    .interval {
      margin: 0 8px;
    }
    .user_class {
      display: flex;
      flex-direction: column;
      width: 100%;
      background: linear-gradient(
        180deg,
        #ffffff 0%,
        rgba(255, 255, 255, 0.6) 100%
      );
      border-radius: 5px;
      padding: 10px 20px;
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
      .name_img1 {
        display: none;
        justify-content: center;
        flex-shrink: 0;
        align-items: center;
        width: 68px;
        height: 68px;
        background: #018fff;
        border-radius: 50%;
        font-size: 36px;
        margin-right: 14px;
        color: #fff;
      }
      .name_img2 {
        display: flex;
        justify-content: center;
        flex-shrink: 0;
        align-items: center;
        width: 68px;
        height: 68px;
        background: #018fff;
        border-radius: 50%;
        font-size: 36px;
        margin-right: 14px;
        color: #fff;
      }
      .info_text_box {
        display: block !important;
      }
      .user_name {
        font-size: 24px;
        margin-right: 8px;
      }
      .user_describe {
        font-size: 14px;
        color: #018fff;
        span {
          margin: 0 13px 0 0;
          background: #018fff10;
          border-radius: 50px 50px 50px 0px;
          padding: 2px 10px;
        }
      }
      .wrap {
        flex-wrap: wrap;
      }
      .info_center_text {
        margin: 8px 0;
        .box_text {
          font-size: 14px;
          color: #333;
        }
      }

      .interval {
        margin: 0 8px;
      }
      .city {
        display: flex;
        align-items: center;
        height: 24px;
        background: #018fff;
        font-size: 12px;
        color: #fff;
        justify-content: center;
        border-radius: 5px;
        padding: 5px 20px;
      }
      .img_box {
        display: flex;
        align-items: center;
        flex-flow: row wrap;
        .img_item {
          margin-right: 21px;
          min-width: 200px;
          color: #092753;
          font-size: 14px;
          .img_url {
            width: 24px;
            margin-right: 10px;
          }
          .time {
            margin-left: 5px;
            color: red;
            font-weight: 700;
          }
        }
      }
    }
    .max_user {
      display: none;
    }
    .min_user {
      display: flex;
    }
    .user_left {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: calc(100%);
      margin-top: 10px;
      .user_left_item {
        width: calc(100% - 30px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: linear-gradient(
          180deg,
          #ffffff 0%,
          rgba(255, 255, 255, 0.6) 100%
        );
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
        padding: 10px 15px;
        border-radius: 5px;
        overflow: hidden;
        &:first-child {
          margin-bottom: 10px;
        }
        .user_left_num {
          position: relative;
          z-index: 1;
          color: #018fff;
          font-size: 20px;
          font-weight: bold;
          margin: 0 5px;
        }
        .user_left_text {
          font-size: 14px;
          color: #333;
        }
        .user_left_img {
          height: 30px;
          width: auto;
        }
      }
    }
  }
  .title_span {
    color: #333;
    font-weight: 400;
    font-size: 8px;
  }
  .title_text {
    color: #333;
    font-weight: 400;
    font-size: 12px;
  }
}
.table_text {
  margin-left: 20px;
  font-size: 14px;
  color: #333;
}
.footer {
  padding: 0 20px 30px;
}
.footer_title {
  color: #000;
  font-size: 24px;
  font-weight: 500;
  line-height: 34.75px;
  margin: 18px 0;
}
.footer_content {
  line-height: 30px;
  font-size: 18px;
  color: #808994;
}
</style>
