import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/index.vue";
import guidePage from "@/views/guidePage.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/", component: Home },
    { path: "/guidePage", component: guidePage },
  ],
});

export default router;
